<template>
  <div>
    <h1 class="ui-title-page">Book A Field Assistant</h1>
    <div class="triagl triagl-btm"></div>
    <section
      class="section-area section_price bg-gray has-edit"
      v-if="!loading"
    >
      <template v-if="!form_submitted">
        <h2 class="ui-title-block">easy on your pocket</h2>
        <div class="ui-subtitle-block">Enter Your Details</div>
        <div
          class="border-color border-color_small"
          style="margin-bottom: 0"
        ></div>
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <ul class="list-price" style="margin-top: 20px">
                <li class="list-price__item">
                  <h3 class="list-price__title">{{ pricing.title }}</h3>
                  <span class="list-price__total"
                    >KES<strong>{{ pricing.amount }}</strong></span
                  >
                  <ul class="description">
                    <li
                      v-for="(content, j) in pricing.content"
                      :key="`price-content-${j}`"
                    >
                      {{ content }}
                    </li>
                  </ul>
                  <div class="border-color"></div>
                </li>
              </ul>
            </div>
            <div class="col-md-9">
              <form @submit.prevent="submit" class="booking-form">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="text-muted">Contact Information</h3>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Your Name</label>
                      <input
                        type="text"
                        placeholder="Your Name"
                        class="form-control"
                        v-model="user.name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Your Email</label>
                      <input
                        type="text"
                        placeholder="Your Email"
                        class="form-control"
                        v-model="user.email"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Your Phone Number</label>
                      <input
                        type="text"
                        placeholder="Your Phone Number"
                        class="form-control"
                        v-model="user.phone_number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>When would you like to move?</label>
                      <date-picker
                        v-model="user.date"
                        input-class="form-control"
                        placeholder="When would you like to move?"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <h3 class="text-muted">What Are You Lookin For?</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Category</label>
                            <v-select
                              :options="categories"
                              label="name"
                              placeholder="Select Category"
                              v-model="selected_category"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Sub Category</label>
                            <input
                              type="text"
                              disabled
                              v-if="selected_category == null"
                              class="form-control"
                              placeholder="Sub Category"
                            />
                            <input
                              type="text"
                              disabled
                              v-if="
                                selected_category != null &&
                                selected_category.children.length == 0
                              "
                              v-model="selected_category.name"
                              class="form-control"
                            />
                            <v-select
                              v-if="
                                selected_category != null &&
                                selected_category.children.length > 0
                              "
                              :options="selected_category.children"
                              label="name"
                              placeholder="Select Category"
                              v-model="selected_subcategory"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>More Details</label>
                            <textarea
                              v-model="user.category_details"
                              rows="5"
                              placeholder="Number of bedrooms, etc."
                              class="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <h3 class="text-muted">
                        Where would you like to move to?
                      </h3>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Region</label>
                            <select-region v-model="user.region_id" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Location Details</label>
                            <textarea
                              v-model="user.region_details"
                              rows="5"
                              placeholder="Location Details"
                              class="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <button class="btn btn-primary">
                      <i class="lni lni-save"></i>Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
      <template v-if="form_submitted">
        <h2 class="ui-title-block">Success</h2>
        <div class="ui-subtitle-block">We have received your request</div>
        <div
          class="border-color border-color_small"
        ></div>
        <div class="container">
          <div class="card text-center mb-2">
            <p>Your request is being processed. We will get in touch soon with your personalized field assistant.</p>
            <a href="/" class="btn btn-primary mt-2"><i class="lni lni-home"></i>Go Home</a>
          </div>
        </div>
      </template>
    </section>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      pricing: {},
      loading: true,
      user: this.$store.getters.getAuthUser,
      selected_category: null,
      selected_subcategory: null,
      categories: [],
      form_submitted: false,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/content/pricing/${this.$route.params.id}`)
        .then((response) => {
          this.pricing = response.data.pricing;
          this.categories = response.data.categories;
          this.loading = false;
        });
    },

    submit() {
      this.form_submitted = false;
      let data = {
        pricing_id: this.$route.params.id,
        name: this.user.name,
        email: this.user.email,
        phone_number: this.user.phone_number,
        date: new Date(this.user.date).toISOString().slice(0, 10),
        category_id:
          this.selected_subcategory == null
            ? this.selected_category.id
            : this.selected_subcategory.id,
        category_details: this.user.category_details,
        region_id: this.user.region_id,
        region_details: this.user.region_details,
      };

      this.$axios.post("/api/v1/content/book/field-assistant", data).then(() => {
        this.form_submitted = true;
      });
    },
  }
};
</script>
